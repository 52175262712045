<template>
  <div style="width: 100%;">
    <el-collapse v-model="filterName" accordion>
      <el-collapse-item name="filter">
        <template #title>
          <i style="margin-left: 1rem;font-size: 1rem;font-weight: 600;margin-right: .5rem" class="el-icon-search"></i>筛选条件
        </template>
        <div style="height: 15rem" class="flex-def flex-zTopBottom">
          <el-form :model="searchForm" :inline="true" label-width="80px" style="width: 70rem">
            <el-form-item label="商家名称">
              <el-input style="width: 20rem" v-model="searchForm.title" placeholder="商家名称"></el-input>
            </el-form-item>
            <el-form-item v-if="area_show" label="区域范围">
              <yChooseArea v-if="filterName === 'filter'" style="width: 30rem" @change="searchArea" :val="searchForm"></yChooseArea>
            </el-form-item>
            <el-form-item label="行业">
              <yChooseIndustry style="width: 20rem" v-model="searchForm.industry_id"></yChooseIndustry>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker style="width: 20rem" v-model="searchForm.createtime" type="daterange" range-separator="至"
                              start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button @click="searchSubmit" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table v-loading="loading" :data="list" border :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column label="logo" width="60">
        <template #default="scope">
          <el-avatar shape="square" size="small" :src="scope.row.logo | tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="名称" width="200"></el-table-column>
      <el-table-column prop="link_name" label="联系人" width="120"></el-table-column>
      <el-table-column prop="link_phone" label="联系电话" width="120"></el-table-column>
      <el-table-column prop="industry.title" label="所属行业"></el-table-column>
      <el-table-column label="区域" width="260">
        <template #default="s">
          {{ s.row.province_title }}/
          {{ s.row.city_title }}/
          {{ s.row.county_title }}/
          {{ s.row.town_title }}
        </template>
      </el-table-column>
      <!--      <el-table-column prop="sort" label="排序" width="80"></el-table-column>-->
      <!--      <el-table-column prop="nickname" label="管理员" width="120"></el-table-column>-->
      <el-table-column label="创建时间" width="180">
        <template #default="s">
          {{ s.row.created_at | date }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="180">
        <template #default="s">
          <div style="margin-bottom: 1rem">
            <el-button @click="$router.push({
          name:'merchantEdit',
          params:$route.params,
          query:{id:s.row.id}
          })" type="primary" size="mini">编辑</el-button>
            <el-button @click="judge(s.row)" type="primary" size="mini">审核</el-button>
          </div>
          <el-button @click="del(s.row)" size="small">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
import yChooseIndustry from "@/components/y_choose_industry";
import yChooseArea from "@/components/y_choose_area";

export default {
  name: "wait_list",
  components: {
    yChooseIndustry,
    yChooseArea,
  },
  data() {
    return {
      area_show: true,
      searchForm: {
        status:false,
        title: "",
        industry_id: 0,
        createtime: [],
        city_code: "",
        city_title: "",
        county_code: "",
        county_title: "",
        province_code: "",
        province_title: "",
        town_code: "",
        town_title: "",
      },
      list: [],
      total: 0,
      page:1,
      loading: false,
      filterName:"",
    }
  },
  mounted() {
    this.searchSubmit();
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    pageChange(e){
      this.page = e;
      this.searchSubmit();
    },
    judge(item){
      item.status = true;
      this.$api.merchant.update(item).then(()=>{
        this.$message.success("操作成功");
        this.searchSubmit();
      })
    },
    del(item){
      this.$api.merchant.del({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.searchSubmit();
      })
    },
    statusChange(item){
      this.$api.merchant.update(item).then()
    },
    searchSubmit() {
      this.loading=true;
      this.$api.merchant.search({
        page: this.page,
        title: this.searchForm.title,
        industry_id: this.searchForm.industry_id,
        town_code: this.searchForm.town_code,
        time_start: this.searchForm.createtime[0],
        time_end: this.searchForm.createtime[1],
        status:this.searchForm.status
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
        this.loading=false;
      })
    },
    resetSearch() {
      this.searchForm = {
        status:true,
        title: "",
        industry_id: 0,
        createtime: [],
        city_code: "",
        city_title: "",
        county_code: "",
        county_title: "",
        province_code: "",
        province_title: "",
        town_code: "",
        town_title: "",
      }
      this.area_show = false;
      setTimeout(() => {
        this.area_show = true;
      }, 0)
      this.searchSubmit();
    },
    searchArea(e) {
      this.searchForm.province_code = e.province_code;
      this.searchForm.province_title = e.province_title;
      this.searchForm.city_code = e.city_code;
      this.searchForm.city_title = e.city_title;
      this.searchForm.county_code = e.county_code;
      this.searchForm.county_title = e.county_title;
      this.searchForm.town_code = e.town_code;
      this.searchForm.town_title = e.town_title;
    },
  }
}
</script>

<style scoped>

</style>