<template>
  <y_layout>
    <YTabs v-model="activeName">
      <YTabPane name="success" label="商家列表">
        <el-card>
          <normal_list></normal_list>
        </el-card>
      </YTabPane>
      <YTabPane name="wait" label="待审列表">
        <el-card>
          <wait_list></wait_list>
        </el-card>
      </YTabPane>
    </YTabs>
<!--    <el-tabs type="border-card" v-model="activeName">-->
<!--      <el-tab-pane label="商家列表" name="success">-->
<!--        <normal_list></normal_list>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="待审列表" name="wait">-->
<!--        <wait_list></wait_list>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import wait_list from "@/view/merchant/list/wait_list";
import normal_list from "@/view/merchant/list/normal_list";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";

export default {
  name: "index",
  components: {
    YTabPane,
    YTabs,
    y_layout,
    wait_list,
    normal_list,
  },
  data() {
    return {
      activeName: "success"
    }
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped>

</style>